






























































import { useBlob, useMaxOneYear, useTrialBalanceReport } from "@/hooks";
import { FormValue } from "@/hooks/useTrialBalanceReport";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ONE } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { TrialBalanceDTO } from "@/models/interface/generaljournal.interface";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component
export default class TrialBalance extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useMaxOneYear = useMaxOneYear;

  @Ref("formModel")
  formModel!: FormModel;

  dataReport: TrialBalanceDTO[] = [];
  loading = {
    find: false,
    download: false,
  };
  form: FormValue = {
    asOfDate: [],
    maxDate: null,
  };
  columnTable = [
    {
      title: this.$t("lbl_code"),
      dataIndex: "code",
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
    },
    {
      title: this.$t("lbl_beginning_balance"),
      dataIndex: "beginningBalance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_debit"),
      dataIndex: "debit",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_credit"),
      dataIndex: "credit",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_ending_balance"),
      dataIndex: "endingBalance",
      scopedSlots: { customRender: "currency" },
    },
  ];
  formRules = {
    asOfDate: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
  };

  handleFind(value: FormValue): void {
    const { findAll, mapFormToReqParams } = useTrialBalanceReport();
    const params = mapFormToReqParams(value);
    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataReport = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  validateForm(action: "download" | "find"): void {
    this.formModel.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (action === "download") {
        this.handleDownload(this.form);
      } else if (action === "find") {
        this.handleFind(this.form);
      }
    });
  }

  handleDownload(field: FormValue): void {
    const { toDownload } = useBlob();
    const { mapFormToReqParams, download } = useTrialBalanceReport();
    const params = mapFormToReqParams(field);

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "trial-balance.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  resetForm(): void {
    this.formModel.resetFields();
  }

  onCalendarChange(dates: Array<Moment>): void {
    if (!dates || dates.length === 0) return;
    this.form.maxDate = moment(dates[0]).add(ONE, "year");
  }
}
